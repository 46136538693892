


















































































































































































































.is-valid {
  color: blue;
}
.is-nonvalid {
  color: red;
  font-weight: 500;
}
.is-unknown {
  color: #607d8b;
}
