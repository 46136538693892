












































































































































































































































































































































































































































































































































$transaction-color: red;
$transaction-color2: blue;

.head_transaction {
  position: relative;
  font-size: 12px;
  .right {
    position: absolute;
    right: 0;
  }
}

.tbl_transaction {
  min-width: 100%;
  // width: 800px;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;

  td {
    font-size: 12px;
    padding: 1px 2px;
  }
  & + .type_cont {
    td {
      min-height: 30px;
    }

    .td_provider {
      text-align: center;
      // writing-mode: tb-rl;
      // letter-spacing: 4px;
    }

    .td_tit {
      text-align: center;
    }

    .td_str {
      text-align: center;
      font-weight: bold;
      font-size: 13px;
    }

    .tr_cost {
      text-align: right;
      height: 20px;
    }
    .tr_product td {
      height: 20px;
    }
  }

  .align_center {
    text-align: center !important;
  }
  .align_right {
    text-align: right !important;
  }

  .row_head {
    .td_subject {
      padding: 5px;
      text-align: center;
    }
  }
  .tit_transaction {
    font-size: 24px;
  }
  .txt_transaction {
    margin-left: 4px;
    font-size: 18px;
  }

  .td_ellip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .td_claim {
    text-align: center;
  }
  .td_claim div {
    vertical-align: middle;
    display: inline-block;
    padding: 2px;
  }
}

.type_supplier {
  .head_transaction {
    color: $transaction-color;
  }
  .tbl_transaction {
    border: 1px solid $transaction-color;
    & + .type_cont {
      border: 0;
      td {
        border: 1px solid $transaction-color;
      }
      .td_provider {
        color: $transaction-color;
      }
      .td_tit {
        color: $transaction-color;
      }
    }
    .row_head {
      .td_subject,
      .td_tit {
        color: $transaction-color;
      }
      .txt_suffix {
        color: $transaction-color;
      }
    }
    .td_claim {
      color: $transaction-color;
    }
  }
  .bl_1 {
    border-left: 1px solid $transaction-color;
  }
  .bt_1 {
    border-top: 1px solid $transaction-color;
  }
}

.type_recipient {
  .head_transaction {
    color: $transaction-color2;
  }
  .tbl_transaction {
    border: 1px solid $transaction-color2;
    & + .type_cont {
      border: 0;
      td {
        border: 1px solid $transaction-color2;
      }
      .td_provider {
        color: $transaction-color2;
      }
      .td_tit {
        color: $transaction-color2;
      }
    }
    .row_head {
      .td_subject,
      .td_tit {
        color: $transaction-color2;
      }
      .txt_suffix {
        color: $transaction-color2;
      }
    }
    .td_claim {
      color: $transaction-color2;
    }
  }
  .bl_1 {
    border-left: 1px solid $transaction-color2;
  }
  .bt_1 {
    border-top: 1px solid $transaction-color2;
  }
}

.txt_info {
  margin-top: 14px;
  font-size: 12px;
  color: #999;
}

.bt_0 {
  border-top: 0 !important;
}
.bb_0 {
  border-bottom: 0 !important;
}

.purchase_layer .inner_comm_layer .layer_body > .group_btn {
  border-top: 0;
}
